// This is needed as scss won't preprocess yaml. This variable used for photos referenced in CSS styles.css.
$baseurl: "";

// import partials
@import "../_sass/base";
@import "../_sass/custom-variables";
@import "../node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
// @import "../bower_components/bootstrap-sass/assets/stylesheets/bootstrap-compass"; // What does this do?
@import "../node_modules/@fortawesome/fontawesome-pro/css/all.min.css";
@import "../_sass/base";
